<template>
  <v-sheet>
    <cit-data-table
      :appends="[
        'customer.display_name'
      ]"
      :fixed-filters="fixedFilters"
      :headers="headers"
      disable-actions
      resource-path="form-submissions"
      show-expand
      single-expand
    >

      <template v-slot:item.status="{item, value}">
        <inline-edit-activity-status :item="item" :value="value"/>
      </template>

      <template v-slot:expanded-item="{ item }">
        <v-simple-table class="my-4">
          <template v-slot:default>
            <tbody>
            <tr v-for="(field, index) in item.fields" :key="index">
              <td>{{ field.field_label }}</td>
              <td v-html="field.field_value"></td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>

    </cit-data-table>

  </v-sheet>
</template>

<script>
import CitDataTable from '@/components/layout/CitDataTable'
import InlineEditActivityStatus from '@/views/ActivityForms/InlineEditActivityStatus'

export default {
  name: 'ActivityFormsList',
  components: {
    InlineEditActivityStatus,
    CitDataTable
  },
  data () {
    return {
      headers: [
        {
          text: 'ID',
          value: 'id',
          filterType: 'text',
          width: '100',
          sortable: true
        },
        {
          text: 'Formular',
          value: 'form_name_verbose',
          filterName: 'form_name',
          filterType: 'simple-select',
          filterOptions: [
            {
              value: 'rent',
              text: 'Inchirieri'
            },
            {
              value: 'seap',
              text: 'Seap'
            },
            {
              value: 'leasing',
              text: 'Leasing & Rate'
            },
            {
              value: 'contact',
              text: 'Contact'
            },
            {
              value: 'return',
              text: 'Formular retur produse'
            },
            {
              value: 'wishlist',
              text: 'Formular wishlist'
            },
            {
              value: 'we_import_anythings',
              text: 'Aducem orice de pe planeta'
            },
            {
              value: 'gdpr',
              text: 'Cerere GDPR'
            },
            {
              value: 'services',
              text: 'Formular: ce servicii ai nevoie'
            },
            {
              value: 'buy',
              text: 'Formular: Cumparam produse it'
            },
            {
              value: 'suggestions',
              text: 'Formular: Sugestii si reclamatii'
            }
          ],
          width: '150',
          sortable: false
        },
        {
          text: 'Client',
          value: 'customer.client_display_name',
          width: '150',
          sortable: false
        },
        {
          text: 'Agent',
          value: 'agent.full_name',
          filterName: 'agent_id',
          filterType: 'agent',
          width: '150',
          sortable: false
        },
        {
          text: 'Status',
          value: 'status',
          filterName: 'status_id',
          filterType: 'simple-select',
          filterOptions () {
            if (this.$store.getters.formSubmissionsStatusesIsLoaded) {
              return this.$store.getters.formSubmissionsStatuses.data.map(item => {
                return {
                  value: item.id,
                  text: item.name
                }
              })
            } else {
              return []
            }
          },
          width: '150',
          sortable: false
        },
        {
          text: 'Data',
          value: 'created_at',
          filterType: 'date-range',
          display: 'date',
          align: 'center',
          width: '100',
          sortable: true
        }
      ]
    }
  },
  computed: {
    fixedFilters () {
      const formName = this.$route.params.form
      if (formName === 'all') {
        return {}
      } else {
        return {
          'form_name': formName
        }
      }
    }
  },
  created () {
    this.$store.dispatch('agents/loadItems')
    this.$store.dispatch('loadFormSubmissionsStatuses')
  }

}
</script>
