<template>
  <v-autocomplete
    v-model="selectOption"
    :items="items"
    class="filter-select text-sm"
    dense
    hide-details
    item-text="name"
    item-value="id"
    label="Status"
    outlined
    single-line
  >

  </v-autocomplete>
</template>

<script>
export default {
  name: 'InlineEditActivityStatus',
  inject: ['table'],
  props: {
    item: {
      required: true
    },
    value: {
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    items () {
      return this.paginatedData.data
    },
    paginatedData () {
      return this.$store.getters.formSubmissionsStatuses
    },
    selectOption: {
      get () {
        return this.value
      },
      set (value) {
        this.loading = true
        this.$http.patch(`form-submissions/${this.item.id}`, {status_id: value})
          .then(() => {
            this.table.loadItems()
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  },

  created () {
    if (!this.$store.getters.formSubmissionsStatusesIsLoaded) {
      this.$store.dispatch('loadFormSubmissionsStatuses')
    }
  }
}
</script>
